<template>
	<div  style="background-color: #000000;min-height: 100%;">
		<!-- <table border style="width:122px; position: absolute;padding-bottom: 0;">
			<tr>
				<th>{{list1.thead[0]}}</th>
			</tr>
			<tr v-for="(item,i) in list1.tbody" :key="i" style="background-color: #000000;">
				<td>{{item.trow[0]}}</td>
			</tr>
		</table> -->
		<table border>
			<tr>
				<th v-for="(item,b) in list1.thead" :key="b">{{item}}</th>
			</tr>
			<tr v-for="(item,indexs) in list1.tbody" :key="indexs">
				<td v-for="(item2,index) in item.trow" :key="index" :style="index>0&&item2.spec==1?{'cursor':'pointer'}:''"
					@click="clickTupu(item2)">
					<div v-if="index==0">{{item2}}</div>
					<div v-else>{{item2.num}}</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
	// import countTo from 'vue-count-to';
	export default {
		name: "zhuansu",
		components: {
			// countTo,
		},
		data() {
			return {
				list1: {},
				t: null,
				t2: null
			}
		},
		methods: {
			//数据上下浮动
			floatNumber(num) {
				let flag = false;
				if (typeof num == "string") {
					num = parseFloat(num.substr(0, num.length - 1));
					flag = true;
				}
				if (num == 0) {
					if (flag) {
						return "0%";
					} else {
						return 0;
					}
				}
				let start = -1;
				let end = 1;
				let step = (Math.random() * (end - start) + start) / 100;
				let result = parseFloat((num + step).toFixed(2));
				if (flag) {
					return result > 0 ? result + "%" : "0.01%";
				} else {
					return result > 0 ? result : 0.01;
				}
			},
			get() {
				clearInterval(this.t2);
				this.$get("backend-api/web/screen/runSpeed", null).then((res) => {
					if (res.code == 1000) {
						this.list1 = res.data;
						this.t2 = setInterval(() => {
							for (let i in this.list1.tbody) {
								let item = this.list1.tbody[i];
								if (i > 4) {
									for (let j in item.trow) {
										let item2 = item.trow[j];
										if (j > 0) {
											item2.num = this.floatNumber(item2.num)
										}
									}
								}
							}
						}, 1000);
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			clickTupu(item) {
				if (item.spec == 1) {
					this.Bus.$emit("clickDanti",item)
				}
			}
		},
		mounted() {
			this.get();
			this.t = setInterval(this.get, 60000);
		},
		beforeDestroy() {
			clearInterval(this.t);
			clearInterval(this.t2);
		}
	}
</script>

<style lang="scss" scoped>
	table {
		display: inline-block;
		width: 100%;
		overflow: auto;

		tr {
			display: flex;

			th {
				background-color: #404040;
				flex: 1;
				min-height: 40px;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				color: rgba(255, 255, 255, 0.85);
				line-height: 40px;
				min-width: 120px;
			}

			td {
				flex: 1;
				min-height: 40px;
				text-align: center;
				font-size: 14px;
				font-weight: bold;
				color: rgba(255, 255, 255, 0.85);
				line-height: 40px;
				min-width: 120px;
			}
		}
	}
</style>
